<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<OrderNav></OrderNav>
			</div>
			<div class="admin-col whole_000 flex_bd">
				<div class="order_box">
					<div class="order-top whole_000">
						<div class="or-top-b">{{$t('meun11_4')}}</div>
					</div>
					<div class="order-top order-body whole_fff">
						<div class="order-table orf_table">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="createTime" :label="$t('czth1')">
								</el-table-column>
								<el-table-column prop="fromUnit" :label="$t('czth2')">
								</el-table-column>
								<el-table-column prop="toUnit" :label="$t('czth3')">
								</el-table-column>
								<el-table-column prop="fromAmount" :label="$t('czth4')">
								</el-table-column>
								<el-table-column prop="price" :label="$t('czth5')">
								</el-table-column>
								<el-table-column prop="toAmount" :label="$t('czth6')">
								</el-table-column>
								<el-table-column prop="fee" :label="$t('czTxt8')">
								</el-table-column>
								<template slot="empty">
									<img src="../../assets/photo/exte1.png" alt="">
									<p>{{$t('czEmpty')}}</p>
								</template>
							</el-table>
						</div>
						<div class="exte-box">
							<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
								layout="prev, pager, next" :total="total" :background="true"></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		convertList
	} from '@/api/api/user'
	import OrderNav from '@/components/OrderNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			OrderNav,
			Foot
		},
		data() {
			return {
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				total: 10
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				convertList({
					pageNo: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					this.tableData = res.data.content
					this.total = res.data.totalElements
					this.pageSize = res.data.size
				})
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			}
		}
	}
</script>
